@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes flip-in-hor-top {
  0% {
    transform: rotateX(-80deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
