.tippy-box[data-theme~='light'] {
  color: #26323D;
  box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25);
  background-color: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #EFEFEF;
}

.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #FFFFFF;
}

.tippy-box[data-theme~='light'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #FFFFFF;
}

.tippy-box[data-theme~='light'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #FFFFFF;
}

.tippy-box[data-theme~='light'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #FFFFFF;
}

.tippy-box[data-theme~='light'] > .tippy-backdrop {
  background-color: #FFFFFF;
}

.tippy-box[data-theme~='light'] > .tippy-svg-arrow {
  fill: #FFFFFF;
}

.tippy-box[data-theme~='light'] > .tippy-content {
  padding: 12px;
}
