$body-width: 100% !default;
$body-font-size: 16px !default;
$default-padding: 12px !default;

// $BorderStyle: solid;
// $BorderSizes: () !default;
// $BorderRadiusSizes: () !default;

$FontSizes: (
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  32,
  38,
  42,
  48,
  64
) !default;

$MarginPaddingSizes: (
  0,
  3,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  14,
  15,
  16,
  18,
  20,
  24,
  28,
  30,
  32,
  42,
  48,
  64
) !default;
