@import '_variables.scss';
@import '~@nappr/nappr-styles/styles.scss';
@import './tippy/';
@import '_spinner.scss';
@import '_tippy.scss';
@import '_markers.scss';
@import '_animations.scss';
@import '_leaflet.scss';

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #90cccb;
  font-family: 'Mulish', helvetica, arial, sans-serif;
}

* {
  outline: none;
  font-family: 'Mulish', helvetica, arial, sans-serif;
}

button:disabled {
  background: #ffffff;
  color: #b7b7b7;
}

.is-mulish {
  font-family: 'Mulish', helvetica, arial, sans-serif;
}

.is-pacifico {
  font-family: 'Pacifico', cursive;
}

.debug * {
  outline: 1px solid rgba(255, 0, 0, 0.2);
}

.react-share__ShareButton {
  width: 32px;
  max-width: 32px;
  min-width: 32px;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
}

#root,
#app-container {
  @extend .is-full-layout;

  background: #90cccb;
}

#application-page {
  @extend .is-relative;
  @extend .is-full-layout;
}

#application-loader {
  @extend .is-overlay;

  & .wrapper {
    @extend .is-relative;
    @extend .is-full-layout;
  }
}
