.leaflet-container {
  height: 100%;
  width: 100%;
  background-color: #90cccb;
}

.leaflet-right .leaflet-control {
  margin-right: 12px;
}

.leaflet-popup-close-button {
  top: 3px !important;
  right: 3px !important;
}

.leaflet-popup-content-wrapper {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}

.leaflet-dist-marker {
  border: 0 !important;
  font-size: 0.7em !important;
  line-height: 2em !important;
}
